"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trackUpgrade = exports.trackSubscribe = exports.trackLead = exports.customTrack = void 0;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
/**
 * Fbq has a different payload structure
 * this is to convert gtag payload to fbq payload assuming not using custom track (which takes a more versatile payload)
 * @param analyticsPayload - The event payload, default to gtag structure
 */
var convertToFbqPayload = function convertToFbqPayload(analyticsPayload) {
  var fbqPayload = _objectSpread({
    email: analyticsPayload.email
  }, analyticsPayload.amount && {
    currency: "USD",
    value: analyticsPayload.amount
  });
  return fbqPayload;
};

/**
 * customTrack will fire event to both fbq and gtag
 * ttp is integrated with gtag so not doing a separate track
 * @param eventName - The name of the event to track.
 * @param analyticsPayload - The event payload, default to gtag structure
 */
var customTrack = exports.customTrack = function customTrack(eventName, analyticsPayload) {
  var _window, _window2;
  // facebook pixel
  if (typeof ((_window = window) === null || _window === void 0 ? void 0 : _window.fbq) === "function") {
    window.fbq("track", eventName, convertToFbqPayload(analyticsPayload));
  }
  // gtm + tt
  // can potentially do tt separately if not using gtag manager
  if (typeof ((_window2 = window) === null || _window2 === void 0 ? void 0 : _window2.gtag) === "function") {
    window.gtag("event", eventName, analyticsPayload);
  }
};
var trackLead = exports.trackLead = function trackLead(analyticsPayload) {
  customTrack("Lead", analyticsPayload);
};
var trackSubscribe = exports.trackSubscribe = function trackSubscribe(analyticsPayload) {
  customTrack("Subscribe", analyticsPayload);
};
var trackUpgrade = exports.trackUpgrade = function trackUpgrade(analyticsPayload) {
  customTrack("Upgrade", analyticsPayload);
};